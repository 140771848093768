import React from "react";
import { FaPhoneAlt, FaViber, FaWhatsapp } from "react-icons/fa";
import SquareButton from "../../Buttons/SquareButton/SquareButton";
import "./LandingImage.css";

import { isMobile } from "../../../constants/constants";
import {
  eventPhoneCall,
  eventViber,
  eventWhatsApp,
} from "../../../constants/functions";

const LandingImage = () => {
  const addOn = isMobile ? "-mob" : "";

  return (
    <div className={`landing-image${addOn}`}>
      <div className={`text${addOn}`}>
        <div>
          <h1>OTKUP VOZILA</h1>
        </div>
        {isMobile ? (
          <>
            <div>
              <h2>NAJBRŽI</h2>
              <h2>NAJPOVOLJNIJI</h2>
              <h2>NAJPOUZDANIJI</h2>
            </div>
          </>
        ) : (
          <div>
            <h2>NAJBRŽI | NAJPOVOLJNIJI | NAJPOUZDANIJI</h2>
          </div>
        )}
        <div className={`button-container${addOn}`}>
          <SquareButton
            Icon={FaPhoneAlt}
            text="Nazovite nas"
            href="tel:+385976175673"
            func={eventPhoneCall}
          />
          <SquareButton
            Icon={FaWhatsapp}
            text="WhatsApp"
            href="https://wa.me/385976175673"
            style={
              isMobile
                ? { marginTop: "10px", backgroundColor: "#25D366" }
                : { marginLeft: "40px", backgroundColor: "#25D366" }
            }
            func={eventWhatsApp}
          />
          <SquareButton
            Icon={FaViber}
            text="Viber"
            href="viber://chat?number=385976175673"
            style={
              isMobile
                ? { marginTop: "10px", backgroundColor: "#7360F2" }
                : { marginLeft: "40px", backgroundColor: "#7360F2" }
            }
            func={eventViber}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingImage;
