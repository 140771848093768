import React from "react";
import CircleButton from "./Buttons/CircleButton/CircleButton";

import { FaViber, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import {
  eventPhoneCall,
  eventViber,
  eventWhatsApp,
} from "../constants/functions";

const Header = () => {
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "white",
        borderBottom: "1px solid #0A0A0A",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ marginLeft: "20px" }}>
        <h1>OTKUP VOZILA</h1>
      </div>

      <div style={{ marginRight: "20px" }}>
        <CircleButton
          Icon={FaPhoneAlt}
          href="tel:+385976175673"
          func={eventPhoneCall}
        />
        <CircleButton
          Icon={FaWhatsapp}
          href="https://wa.me/385976175673"
          style={{ marginLeft: "10px", backgroundColor: "#25D366" }}
          func={eventWhatsApp}
        />
        <CircleButton
          Icon={FaViber}
          href="viber://chat?number=385976175673"
          style={{ marginLeft: "10px", backgroundColor: "#7360F2" }}
          func={eventViber}
        />
      </div>
    </div>
  );
};

export default Header;
