import React from "react";

import { FaPhoneAlt } from "react-icons/fa";
import { GiMagnifyingGlass } from "react-icons/gi";
import { BsCashCoin } from "react-icons/bs";

import "./HowWeDoIt.css";
import HowWeDoItCol from "./HowWeDoItCol";

const HowWeDoIt = () => {
  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>KAKO RADIMO?</h1>
      </div>

      <div className="row-container">
        <HowWeDoItCol
          Icon={FaPhoneAlt}
          title="KONTAKT"
          text="Kontaktirajte nas putem mobitela, WhatsApp-a ili Vibera kako biste nam
          mogli dati podatke o vozilu i dogovoriti mjesto nalaska"
        />

        <HowWeDoItCol
          Icon={GiMagnifyingGlass}
          title="PROCJENA"
          text="Prema dostavljenim podatcima o vozilu dajemo Vam inicijalnu ponudu za
        Vaše vozilo"
        />

        <HowWeDoItCol
          Icon={BsCashCoin}
          title="PRIMOPREDAJA"
          text="Naš operater dolazi do Vas sa svim papirima i novcem. Potpišete
        papire, dobijete novac i Vaš posao je gotov"
        />
      </div>
    </div>
  );
};

export default HowWeDoIt;
