import React from "react";
import "./CircleButton.css";

const CircleButton = ({ Icon, href, style, func }) => {
  return (
    <a target="_blank" href={href}>
      <button type="button" className="circle-button" style={style}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon color="white" size="15" />
        </div>
      </button>
    </a>
  );
};

export default CircleButton;
