import React, { Component } from "react";

export default class CookieCompliance extends Component {
  constructor(props) {
    super(props);
    this.state = { accepted: false };
  }

  render() {
    const { cookiesAllowed, setCookiesAllowed } = this.props;
    const { accepted } = this.state;

    if (accepted) {
      return <div />;
    }

    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(250, 250, 250, 1)",
          width: "100vw",
          borderTop: "1px solid black",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <span style={{ fontSize: 14 }}>
            Koristimo kolačiće za pračenje analitike o posjetima stranici.
          </span>

          <div style={{ display: "flex", flex: 1, justifyItems: "flex-end" }}>
            <div style={{ flex: 1 }}></div>
            <button
              onClick={() => this.setState({ accepted: true })}
              type="button"
              style={{
                border: "none",
                backgroundColor: "#f52226",
                color: "white",
                marginRight: "1rem",
                borderRadius: 5,
                padding: "0.5rem",
              }}
            >
              Prihvaćam
            </button>
            <button
              onClick={() => {
                this.setState({ accepted: true });
                setCookiesAllowed(false);
              }}
              type="button"
              style={{
                border: "black solid 1px",
                backgroundColor: "white",
                borderRadius: 5,
                padding: "0.5rem",
              }}
            >
              Ne prihvaćam
            </button>
          </div>
        </div>
      </div>
    );
  }
}
