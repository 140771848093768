import React from "react";
import "./Region.css";

import { GrLocation } from "react-icons/gr";

import { isMobile } from "../../../constants/constants";

const Region = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="region-container-mob"
    >
      <h1>GDJE RADIMO?</h1>
      {isMobile ? (
        <div style={{ width: "100vw" }}>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              padding: "2rem",
            }}
          >
            <GrLocation size={50} color="red" />
            <h2>CIJELA HRVATSKA</h2>
            <span>
              Kontaktirajte nas iz cijele Republike Hrvatske. Dolazimo kod Vas
              na cijelom području Hrvatske, čak i na otocima. Po dogovoru,
              dolazimo kod Vas i na lokacije van RH.
            </span>
          </div>

          <div className="croatia-mob" style={{ flex: 1 }} />
        </div>
      ) : (
        <div style={{ width: "100vw", height: "70vh", display: "flex" }}>
          <div className="croatia" style={{ flex: 1 }} />
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              padding: "2rem",
            }}
          >
            <GrLocation size={50} color="red" />
            <h2>CIJELA HRVATSKA</h2>
            <span>
              Kontaktirajte nas iz cijele Republike Hrvatske. Dolazimo kod Vas
              na cijelom području Hrvatske, čak i na otocima. Po dogovoru,
              dolazimo kod Vas i na lokacije van RH.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Region;
