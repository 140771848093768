import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "center",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          textAlign: "center",
          backgroundColor: "#f52226",
        }}
      >
        <span style={{ color: "white" }}>
          @2022 BlueBitLine | Sva prava pridržana
        </span>
      </div>
    );
  }
}
