import React from "react";
import "./WhatWeDo.css";

import { IoCarSportOutline } from "react-icons/io5";
import { isMobile } from "../../../constants/constants";

const WhatWeDo = () => {
  const addOn = isMobile ? "-mob" : "";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      className={`what-container${addOn}`}
    >
      <h1>KOJE AUTE OTKUPLJUJEMO?</h1>
      {isMobile ? (
        <div style={{ width: "100vw" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoCarSportOutline size={300} color="#e30004" />
          </div>

          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>OTKUPLJUJEMO SVA VOZILA</h2>
            <span>
              Otkupljujemo sva ispravna, neispravna i karambolirana vozila svih
              marki od 2003 godine. SUV vozila, Audi, Mercedes, BMW, VW, sva
              luksuzna vozila...
            </span>
          </div>
        </div>
      ) : (
        <div style={{ width: "100vw", height: "40vh", display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoCarSportOutline size={300} color="#e30004" />
          </div>

          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>OTKUPLJUJEMO SVA VOZILA</h2>
            <span>
              Otkupljujemo sva ispravna, neispravna i karambolirana vozila svih
              marki od 2003 godine. SUV vozila, Audi, Mercedes, BMW, VW, sva
              luksuzna vozila...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatWeDo;
