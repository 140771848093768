import React from "react";

import { FaPhoneAlt, FaViber, FaWhatsapp } from "react-icons/fa";
import { isMobile } from "../../../constants/constants";
import SquareButton from "../../Buttons/SquareButton/SquareButton";

import "./ContactUs.css";

const ContactUs = () => {
  const addOn = isMobile ? "-mob" : "";

  return (
    <div className={`contact-container${addOn}`}>
      <h1>OBRATITE NAM SE S POVJERENJEM</h1>
      <div className={`contact-row${addOn}`}>
        <SquareButton
          Icon={FaPhoneAlt}
          text="Nazovite nas"
          href="tel:+385976175673"
          style={
            isMobile
              ? { backgroundColor: "#f52226", marginBottom: "10px" }
              : { backgroundColor: "#f52226" }
          }
        />
        <SquareButton
          Icon={FaWhatsapp}
          text="WhatsApp"
          href="https://wa.me/385976175673"
          style={
            isMobile
              ? { backgroundColor: "#25D366", marginBottom: "10px" }
              : { backgroundColor: "#25D366" }
          }
        />
        <SquareButton
          Icon={FaViber}
          text="Viber"
          href="viber://chat?number=385976175673"
          style={
            isMobile
              ? { backgroundColor: "#7360F2", marginBottom: "10px" }
              : { backgroundColor: "#7360F2" }
          }
        />
      </div>
    </div>
  );
};

export default ContactUs;
