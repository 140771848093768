import "./style/large.css";
import "./style/small.css";
import Header from "./components/Header";
import LandingImage from "./components/parts/LandingImage/LandingImage";
import HowWeDoIt from "./components/parts/HowWeDoIt/HowWeDoIt";
import Region from "./components/parts/Region/Region";
import WhatWeDo from "./components/parts/WhatWeDo/WhatWeDo";
import ContactUs from "./components/parts/ContactUs/ContactUs";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import CookieCompliance from "./components/CookieCompiance";

function App() {
  // useEffect(() => {
  //   ReactGA.initialize("G-Z5HMYK73Z3");
  //   ReactGA.pageview("otkup-vozila-ga.com");
  // }, []);

  const [cookiesAllowed, setCookiesAllowed] = useState(true);

  return (
    <div>
      <header>
        <Header />
      </header>
      <LandingImage />
      <Region />
      <HowWeDoIt />
      <WhatWeDo />
      <ContactUs />

      <CookieCompliance
        cookiesAllowed={cookiesAllowed}
        setCookiesAllowed={setCookiesAllowed}
      />

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
