import React from "react";
import "./HowWeDoIt.css";

const HowWeDoItCol = ({ Icon, title, text }) => {
  return (
    <div className="col">
      <Icon size={50} color="white" />
      <h2>{title}</h2>
      <span>{text}</span>
    </div>
  );
};

export default HowWeDoItCol;
