import React from "react";
import "./SquareButton.css";

const SquareButton = ({ Icon, href, text, style, func }) => {
  return (
    <a
      target="_blank"
      href={href}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
      }}
    >
      <button className="square-button" style={style}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="icon-container">
            <Icon color="white" size="15" />
          </div>
          <span>{text}</span>
        </div>
      </button>
    </a>
  );
};

export default SquareButton;
